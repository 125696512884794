.container {
  background-color: var(--layers-surface);
  display: flex;
  justify-content: center;
  height: 100%;
}

.paddingContainer {
  padding: 0 5rem 2.5rem;
  max-width: unset;
}

.noPaddingContainer {
  padding: 0 0rem 2rem;
}

@media (max-width: 850px) {
  .paddingContainer {
    padding: 0 0 2.5rem;
  }
}
