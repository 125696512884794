.defaultContainer {
  background-color: var(--layers-surface);
  display: flex;
  justify-content: center;
  height: 100vh;
  overflow: scroll;
}

.withImageContainer {
  background-color: var(--layers-surface);
  display: flex;
  justify-content: center;
  height: 100vh;
}

.rightContainer {
  display: flex;
  margin-right: auto;
  overflow: scroll;
}

.leftContainer {
  display: flex;
  margin-left: auto;
  overflow: scroll;
}

.centerContainer {
  display: flex;
}

.imageContainer {
  width: calc((100% - 520px));
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.imageStyle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: brightness(75%);
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  width: 100%;
}

@media (max-width: 850px) {
  .imageContainer {
    display: none;
  }
  .leftContainer {
    margin-left: unset;
    overflow: inherit;
  }
  .rightContainer {
    margin-right: unset;
    overflow: inherit;
  }
  .defaultContainer {
    height: 100%;
  }
  .withImageContainer {
    height: 100%;
  }
}
