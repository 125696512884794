.countryCodeAsideIndex {
  z-index: 35;
}

.countryCodeAsideContainer {
  padding: 1rem;
}
.scroll {
  overflow-y: auto;
  overscroll-behavior-y: contain;
  scroll-snap-type: y mandatory;
}

@media (max-width: 850px) {
  .scroll {
    overflow-y: scroll;
    overscroll-behavior-y: auto;
    scroll-snap-type: y mandatory;
  }
}
